



















import Vue from 'vue';

interface Artist {
  name: string;
  releases: Release[];
}

interface Release {
  name: string;
  year: number;
  imageURL: string;
  linkURL: string;
}

export default Vue.extend({
  data() {
    return {
      artists: [
        {
          name: 'Ends Well',
          releases: [
            {
              name: 'Orson',
              year: 2017,
              imageURL: '',
              linkURL: '',
            },
          ],
        },
        {
          name: 'The Shivas',
          releases: [
            {
              name: 'Freezing to Death',
              year: 2010,
              imageURL: '',
              linkURL: '',
            },
          ],
        },
        {
          name: 'Tomorrows Tulips',
          releases: [
            {
              name: 'Tired',
              year: 2010,
              imageURL: '',
              linkURL: '',
            },
          ],
        },
        {
          name: 'The Mystery Lights',
          releases: [
            {
              name: 'Teenage Catgirls And The Mystery Lightshow',
              year: 2009,
              imageURL: '',
              linkURL: '',
            },
          ],
        },
        {
          name: 'Arms & Legs',
          releases: [
            {
              name: 'Nothing Ever Was',
              year: 2009,
              imageURL: '',
              linkURL: '',
            },
            {
              name: 'Arms and Legs',
              year: 2007,
              imageURL: '',
              linkURL: '',
            },
            {
              name: 'Alice',
              year: 2005,
              imageURL: '',
              linkURL: '',
            },
          ],
        },
        {
          name: 'The Ten Thousand Dollar Tattoo',
          releases: [
            {
              name: 'A Cottage By the Sea',
              year: 2008,
              imageURL: '',
              linkURL: '',
            },
          ],
        },
        {
          name: 'The Westfield Mining Disaster',
          releases: [
            {
              name: 'Everyone’s So Spiteful In This Town',
              year: 2008,
              imageURL: '',
              linkURL: '',
            },
          ],
        },
      ] as Artist[],
    };
  },
  metaInfo() {
    return {
      title: 'Label - Closet Trekkie',
    };
  },
});
